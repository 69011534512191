import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

function AddProjectForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [message, setMessage] = useState('');
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api.mayaksecure.com:5000/category/');
        setCategories(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    const options = event.target.options;
    const selected = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selected.push(options[i].value);
      }
    }
    setSelectedCategories(selected);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      telegramId,
      title,
      description,
      price,
      categoryIds: selectedCategories,
    };

    try {
      await axios.post('https://api.mayaksecure.com:5000/order/', data);
      setMessage('Проект успешно добавлен на биржу');
      setTitle('');
      setDescription('');
      setPrice('');
      setSelectedCategories([]);
    } catch (e) {
      console.log(e);
      setMessage('Ошибка при добавлении проекта');
    }
  };

  return (
    <div className='AddWorkForm'>
      <form onSubmit={handleSubmit}>
        <div className="inputBlock">
          <input 
            type="text" 
            placeholder='Заголовок' 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="inputBlock">
          <textarea 
            placeholder='Описание' 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="inputBlock">
          <input 
            type="text" 
            placeholder='Желаемая стоимость в TON' 
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>

        <div className="inputBlock">
          <select 
            multiple 
            value={selectedCategories}
            onChange={handleCategoryChange}
            required
          >
            <option value=''>Категория</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>
                {category.nameRu}
              </option>
            ))}
          </select>
        </div>

        <button type="submit">Добавить проект</button>
        {message && <div className='message'>{message}</div>}
      </form>
    </div>
  );
}

export default AddProjectForm;
