import React from 'react'
import HomePreview from '../components/Home/HomePreview'
import ChatBlock from '../components/Chat/ChatBlock'

const mockItems = [
  {
    name: 'Arlene Mckinney',
    avatar: 'https://ru-static.z-dn.net/files/ddf/9a4d30d8705b9fab1e840cf35c1016de.jpg',
    latestMessage: 'Hello!!!!'
  },
  {
    name: 'Arlene Mckinney',
    avatar: 'https://ru-static.z-dn.net/files/ddf/9a4d30d8705b9fab1e840cf35c1016de.jpg',
    latestMessage: 'Hello!!!!'
  },
  {
    name: 'Arlene Mckinney',
    avatar: 'https://ru-static.z-dn.net/files/ddf/9a4d30d8705b9fab1e840cf35c1016de.jpg',
    latestMessage: 'Hello!!!!'
  },
  {
    name: 'Arlene Mckinney',
    avatar: 'https://ru-static.z-dn.net/files/ddf/9a4d30d8705b9fab1e840cf35c1016de.jpg',
    latestMessage: 'Hello!!!!'
  },
]

function Chat() {
  return (
    <div className='Chat'>
      <HomePreview />

      <div className="Chat__container">
          {mockItems.map((item, index) => (
            <ChatBlock
              key={index}
              name={item.name}
              avatar={item.avatar}
              latestMessage={item.latestMessage}
            />
          ))}
      </div>
    </div>
  )
}

export default Chat