// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    z-index: 1000;
    opacity: 0.9;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
  }
  
  .notification.success {
    background-color: #4caf50;
  }
  
  .notification.error {
    background-color: #f44336;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 0.9; }
  }
  
  @keyframes fadeOut {
    from { opacity: 0.9; }
    to { opacity: 0; }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Tasks/Notification.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,eAAe;IACf,uCAAuC;IACvC,yCAAyC;EAC3C;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,OAAO,UAAU,EAAE;IACnB,KAAK,YAAY,EAAE;EACrB;;EAEA;IACE,OAAO,YAAY,EAAE;IACrB,KAAK,UAAU,EAAE;EACnB","sourcesContent":[".notification {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    padding: 10px 20px;\n    border-radius: 5px;\n    color: white;\n    z-index: 1000;\n    opacity: 0.9;\n    font-size: 14px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;\n  }\n  \n  .notification.success {\n    background-color: #4caf50;\n  }\n  \n  .notification.error {\n    background-color: #f44336;\n  }\n  \n  @keyframes fadeIn {\n    from { opacity: 0; }\n    to { opacity: 0.9; }\n  }\n  \n  @keyframes fadeOut {\n    from { opacity: 0.9; }\n    to { opacity: 0; }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
