import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

function AddWorkForm() {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [cover, setCover] = useState(null);
  const [message, setMessage] = useState('');
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api.mayaksecure.com:5000/category/');
        setCategories(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchCategories();
  }, []);

  const handleFileChange = (event) => {
    setCover(event.target.files[0]);
  };

  const handleCategoryChange = (event) => {
    const value = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedCategories(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('telegramId', telegramId);
    formData.append('title', title);
    formData.append('text', text);
    selectedCategories.forEach((categoryId, index) => {
      formData.append(`categoryIds[${index}]`, categoryId);
    });
    if (cover) {
      formData.append('cover', cover);
    }

    try {
      await axios.post('https://api.mayaksecure.com:5000/advertisements/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setMessage('Работа успешно добавлена');
      // Сброс формы
      setTitle('');
      setText('');
      setSelectedCategories([]);
      setCover(null);
    } catch (e) {
      console.log(e);
      setMessage('Ошибка при добавлении работы');
    }

    // Очистка сообщения через 5 секунд
    setTimeout(() => setMessage(''), 5000);
  };

  return (
    <div className='AddWorkForm'>
      {message && <div className='message'>{message}</div>}
      <form onSubmit={handleSubmit}>
        <div className="inputBlock">
          <input 
            type="text" 
            placeholder='Заголовок' 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="inputBlock">
          <textarea 
            placeholder='Описание' 
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
        </div>

        <div className="inputBlock">
          <select 
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            required
          >
            <option value=''>Категория</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>
                {category.nameRu}
              </option>
            ))}
          </select>
        </div>

        <div className="inputBlock">
          <input 
            type="file" 
            onChange={handleFileChange}
          />
        </div>

        <button type="submit">Добавить работу</button>
      </form>
    </div>
  );
}

export default AddWorkForm;
