import React from 'react'
import { BsSearch, BsBell, BsEmojiLaughing } from "react-icons/bs";

function HomePreview(props) {
  return (
    <div className="HomeOne">
        <div className="homeOne__container">
            <div className="search">
                <div className="leftIcon">
                    <BsEmojiLaughing />
                </div>

                <div className="inputBlock">
                    <div className="icon">
                        <BsSearch />
                    </div>

                    <input type="text" placeholder='Поиск услуг' />
                </div>

                <div className="notificationSearch">
                    <BsBell />
                </div>
            </div>
            {props.title ? (
                <div className="title">
                    {props.title}
                </div>
            ) : null}

            {props.text ? (
                <div className="text">
                    {props.text}
                </div>
            ) : null}
        </div>
    </div>
  )
}

export default HomePreview
