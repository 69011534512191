import React, { useState, useEffect } from 'react';
import HomePreview from '../components/Home/HomePreview';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

function Balance() {
  const [balanceTON, setBalanceTON] = useState(0);
  const [balanceMAYAK, setBalanceMAYAK] = useState(0);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setBalanceTON(response.data.balance);
        setBalanceMAYAK(response.data.balanceMayak);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    if (telegramId) {
      fetchData();
    }
  }, [telegramId]);

  return (
    <div className='Balance'>
      <HomePreview />
      <div className="Balance__container">
        <div className="balanceBlock">
          Баланс <span>TON</span>: {balanceTON}
        </div>
        <div className="balanceBlock">
          Баланс <span>$LS</span>: {balanceMAYAK}
        </div>
        <div className="button">
          <a href="#">Пополнить</a>
        </div>
        <div className="button">
          <a href="#">Вывести</a>
        </div>
        <div className="title">
          История транзакций - пусто
        </div>
      </div>
    </div>
  );
}

export default Balance;
