import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomePreview from '../components/Home/HomePreview';
import ProfileHeader from '../components/Profile/ProfileHeader';
import ProfileDescription from '../components/Profile/ProfileDescription';
import ReviewsBlock from '../components/Profile/ReviewsBlock';
import Portfolio from '../components/Profile/Portfolio';
import WebApp from '@twa-dev/sdk';

const defaultAvatarUrl = 'https://pixelbox.ru/wp-content/uploads/2021/04/ava-youtube-standoff-82.jpg';

function Profile() {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setUser(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setError('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchData();
  }, [telegramId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className='Profile'>
      <HomePreview />

      <div className="Profile__container">
        <ProfileHeader
          avatar={user.avatar || defaultAvatarUrl}
          name={user.nickname || 'No name provided'}
          specialnost={user.specialnost || 'No specialnost provided'}
        />

        <ProfileDescription
          text={user.description || 'No description provided'}
        />

        <ReviewsBlock
          reviews={user.reviews || 0}
          lastReviewText={user.lastReview?.text || 'No review'}
          lastReviewAuthor={user.lastReview?.author || 'Unknown author'}
          rating={user.rating || 0}
        />
      </div>
    </div>
  );
}

export default Profile;
