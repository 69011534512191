import React, { useState, useEffect } from 'react';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';
import HomePreview from '../components/Home/HomePreview';

function Settings() {
  const [user, setUser] = useState({
    nickname: '',
    specialnost: '',
    description: ''
  });
  const [message, setMessage] = useState('');
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setUser(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [telegramId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.mayaksecure.com:5000/users/${telegramId}`, user, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMessage('Профиль успешно обновлен');
    } catch (e) {
      console.log(e);
      setMessage('Произошла ошибка');
    }
  };

  return (
    <div className='Settings'>
        <HomePreview />

        <div className="settings__container">
            <div className="title">
                Настройки
            </div>
            <form onSubmit={handleSubmit}>
                <div className='inputBlock'>
                    <label htmlFor="nickname">Ваше имя:</label>
                    <input
                        type="text"
                        id="nickname"
                        name="nickname"
                        value={user.nickname}
                        onChange={handleChange}
                    />
                </div>

                <div className='inputBlock'>
                    <label htmlFor="specialnost">Ваша специальность:</label>
                    <input
                        type="text"
                        id="specialnost"
                        name="specialnost"
                        value={user.specialnost}
                        onChange={handleChange}
                    />
                </div>

                <div className='inputBlock'>
                    <label htmlFor="description">Описание:</label>
                    <textarea
                        id="description"
                        name="description"
                        value={user.description}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">Сохранить</button>
            </form>
            {message && <p className='message'>{message}</p>}
        </div>
    </div>
  );
}

export default Settings;
