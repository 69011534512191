import axios from 'axios';
import React, { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';
import { Link } from 'react-router-dom';
import { BsHouse, BsSearch, BsChatLeft, BsPerson, BsPlusCircleDotted, BsBarChartLine } from "react-icons/bs";

function MobileNav() {
  const [user, setUser] = useState({})

  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`)
        setUser(response.data)
      } catch(e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  return (
    <nav className="mobile-nav">
        <Link to="/" className="bloc-icon">
            <BsHouse />
        </Link>
        <Link to="/dailyreward" className="bloc-icon active">
            <BsBarChartLine  />
        </Link>
        <Link to="/menu" className="bloc-icon">
            <BsPerson />
        </Link>
    </nav>
  )
}

export default MobileNav;
