import axios from 'axios';
import React, { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';
import MenuItem from './MenuItem';
import { BsBank, BsBox, BsGear, BsBalloon } from "react-icons/bs";

function MenuItems() {
  const [user, setUser] = useState({})

  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`)
        setUser(response.data)
      } catch(e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  return (
    <div className='MenuItems'>
      <MenuItem icon={<BsBank />} text="Баланс" to="/balance" iconColor="#E68536" />
      <MenuItem icon={<BsBox />} text="Профиль" to="/profile" iconColor="#374EF5" />
      <MenuItem icon={<BsGear />} text="Настройки" to="/settings" iconColor="#5C9D52" />
      <MenuItem icon={<BsBalloon />} text="Реферальная система" to="/referal" iconColor="#0014ab" />
    </div>
  );
}

export default MenuItems;
