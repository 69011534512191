import React from 'react';
import { BsArrowUpRightCircle, BsCheckCircle, BsCheck2Square } from "react-icons/bs";

function TaskBlock({ title, goTo, reward, completed, onCheck }) {
  return (
    <div className='TaskBlock'>
      <div className="left">
        <div className="title">
          {title} <br />
          <div className="reward" style={{flexDirection: 'row', display: 'flex', marginTop: 7}}>
            <div className="text">+{reward} points</div>
          </div>
        </div>
      </div>

      <div className="right">
        <a className="go" href={goTo} target="_blank" rel="noopener noreferrer">
          <BsArrowUpRightCircle />
        </a>

        <a className="check" onClick={onCheck} style={{ color: completed ? 'green' : 'gray' }}>
          <BsCheckCircle />
        </a>
      </div>
    </div>
  );
}

export default TaskBlock;
