import React from 'react'

function ChatBlock(props) {
  return (
    <div className='ChatBlock'>
        <div className="left">
            <div className="avatar">
                <img src={props.avatar} alt="" />
            </div>
        </div>

        <div className="right">
            <div className="name">
                {props.name}
            </div>


            <div className="message">
                {props.latestMessage}
            </div>
        </div>
    </div>
  )
}

export default ChatBlock