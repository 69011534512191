// AdvertisementBlock.jsx
import React from 'react';

function AdvertisementBlock({ author, title, text, category, cover }) {
  return (
    <div className="advertisementBlock">
      <div className="coverImage">
        <img src={`https://api.mayaksecure.com:5000/${cover}`} alt="" />
      </div>
      
      <div className="userInfo">
        <div className="avatar">
            <img src={author.avatar} alt="" />
        </div>

        <div className="name">
            {author.name}
        </div>
      </div>

      <div className="text">
        {text}
      </div>

      <div className="button">
            <a href="#">Связаться</a>
      </div>
    </div>
  );
}

export default AdvertisementBlock;
