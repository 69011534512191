import React from 'react'

function ProfileDescription(props) {
  return (
    <div className='ProfileDescription'>
        <div className="title">
            Описание
        </div>

        <div className="text">
            {props.text}
        </div>
    </div>
  )
}

export default ProfileDescription