import React, { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';
import HomePreview from '../components/Home/HomePreview';
import ProjectBlock from '../components/Search/ProjectBlock';
import AdvertisementBlock from '../components/Search/AdvertisementBlock';
import axios from 'axios';

function Search() {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [user, setUser] = useState({});

  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse, categoriesResponse] = await Promise.all([
          axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`),
          axios.get('https://api.mayaksecure.com:5000/category/')
        ]);
        setUser(userResponse.data);
        setCategories(categoriesResponse.data);
        
        // Fetch orders or advertisements based on user role
        if (userResponse.data.role === 'performer') {
          const ordersResponse = await axios.get('https://api.mayaksecure.com:5000/order/');
          setItems(ordersResponse.data);
        } else {
          const advertisementsResponse = await axios.get('https://api.mayaksecure.com:5000/advertisements/');
          setItems(advertisementsResponse.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [telegramId]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredItems = selectedCategory
    ? items.filter(item => item.category && item.category.includes(selectedCategory))
    : items;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <div className='Search'>
      <HomePreview />

      <div className="Search__container">
        <div className="searchHeader">
          <div className="title">
            {user.role === "performer" ? 'Биржа проектов' : 'Биржа исполнителей'}
          </div>

          <div className="selectCategory">
            <select onChange={handleCategoryChange} value={selectedCategory}>
              <option value=''>Все категории</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.nameRu}
                </option>
              ))}
            </select>
          </div>
        </div>

        {filteredItems.map((item, index) => (
          user.role === "performer" ? (
            <ProjectBlock 
              key={index}
              author={item.author}
              date={formatDate(item.date)}
              title={item.title}
              description={item.description}
              responses={item.responses}
              price={item.price}
              category={item.category}
            />
          ) : (
            <AdvertisementBlock 
              key={index}
              author={item.author}
              title={item.title}
              text={item.text}
              category={item.category}
              cover={item.cover}
            />
          )
        ))}
      </div>
    </div>
  );
}

export default Search;
