import React from 'react'
import HomePreview from '../components/Home/HomePreview'
import MenuPreview from '../components/Menu/MenuPreview'
import MenuItems from '../components/Menu/MenuItems'

function Menu() {
  return (
    <div className='Menu'>
        <HomePreview />

        <div className="Menu__container">
            <MenuPreview />

            <MenuItems />
        </div>
    </div>
  )
}

export default Menu