import React from 'react'
import { BsStarFill } from "react-icons/bs";

function ReviewsBlock(props) {
  return (
    <div className='ReviewsBlock'>
        <div className="left">
            <div className="title">
                {props.reviews} отзыва
            </div>

            <div className="subtitle">
                Последний отзыв:
            </div>

            <div className="lastReview">
                {props.lastReviewText}
            </div>

            <div className="lastReviewAuthor">
                - {props.lastReviewAuthor}
            </div>
        </div>

        <div className="right">
            <div className="starGrid">
                <BsStarFill style={{color: '#4B0082'}} />
                <BsStarFill style={{color: '#4B0082'}} />
                <BsStarFill style={{color: '#4B0082'}} />
                <BsStarFill style={{color: '#4B0082'}} />
                <BsStarFill style={{color: '#4B0082'}} />
            </div>

            <div className="button">
                <button>Читать все</button>
            </div>
        </div>
    </div>
  )
}

export default ReviewsBlock;
