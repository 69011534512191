import axios from 'axios';
import React, { useEffect, useState } from 'react';
import WebApp from '@twa-dev/sdk';

const defaultAvatarUrl = 'https://pixelbox.ru/wp-content/uploads/2021/04/ava-youtube-standoff-82.jpg';

function MenuPreview() {
  const [user, setUser] = useState({})

  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`)
        setUser(response.data)
      } catch(e) {
        console.log(e)
      }
    }

    fetchData()
  }, [])

  return (
    <div className="MenuPreview">
      <div className="avatar">
        <img src={defaultAvatarUrl} alt="User Avatar" style={{ borderRadius: '50%', width: '100px', height: '100px' }} />
      </div>

      <div className="name">
        {user.nickname}
      </div>

      <div className="spec">
        {user.specialnost}
      </div>
    </div>
  );
}

export default MenuPreview;
