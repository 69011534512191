import React from 'react'
import HomePreview from '../components/Home/HomePreview'
import AddWorkForm from '../components/AddWork/AddWorkForm'

function AddWork() {
  return (
    <div className='AddWork'>
        <HomePreview />

        <div className="AddWork__container">
            <div className="title">
                Добавить объявление
            </div>

            <AddWorkForm />
        </div>
    </div>
  )
}

export default AddWork