import React, { useState, useEffect } from 'react';
import HomePreview from '../components/Home/HomePreview';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';

const rewardAmounts = [
  { day: 1, amount: 20 },
  { day: 2, amount: 50 },
  { day: 3, amount: 100 },
  { day: 4, amount: 150 },
  { day: 5, amount: 200 },
  { day: 6, amount: 250 },
  { day: 7, amount: 300 },
];

function DailyReward() {
  const [rewardSequence, setRewardSequence] = useState(0);
  const [lastRewardDate, setLastRewardDate] = useState(null);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setRewardSequence(response.data.rewardSequence);
        setLastRewardDate(response.data.lastRewardDate);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (telegramId) {
      fetchUserData();
    }
  }, [telegramId]);

  const isTodayRewardClaimed = () => {
    if (!lastRewardDate) return false;
    const now = new Date();
    const lastReward = new Date(lastRewardDate);
    return now.getDate() === lastReward.getDate();
  };

  return (
    <div className='DailyRewards'>
      <HomePreview />

      <div className="daily__container">
        <div className="title">Ежедневные награды:</div>
        {rewardAmounts.map((reward, index) => (
          <div
            key={index}
            className={`dailyBlock ${rewardSequence >= reward.day ? 'rewarded' : ''}`}
            style={{ backgroundColor: isTodayRewardClaimed() && reward.day === rewardSequence ? '#00ff2674' : '' }}
          >
            <div className="left">
              <div className="title">{reward.day} день</div>
              <div className="text">
                +{reward.amount} <span>$LS</span>
              </div>
            </div>
            <div className="right"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DailyReward;
