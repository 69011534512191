import React, { useState, useEffect } from 'react';
import TaskBlock from './TaskBlock';
import axios from 'axios';
import Notification from './Notification';
import WebApp from '@twa-dev/sdk';

function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [notification, setNotification] = useState(null);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tasksResponse = await axios.get('https://api.mayaksecure.com:5000/tasks');
        setTasks(tasksResponse.data);

        const userResponse = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setCompletedTasks(userResponse.data.completedTasks);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, [telegramId]);

  const handleCheckTask = async (taskId) => {
    try {
      const response = await axios.post('https://api.mayaksecure.com:5000/tasks/check', {
        userId: telegramId,
        taskId
      });

      if (response.data.message === 'Task completed successfully') {
        setCompletedTasks([...completedTasks, taskId]);
        setNotification({ message: "Задача успешно выполнена и баллы начислены.", type: "success" });
      } else {
        setNotification({ message: response.data.error || "Не удалось выполнить задачу.", type: "error" });
      }
    } catch (e) {
      setNotification({ message: "Произошла ошибка", type: "error" });
    }
  };

  return (
    <div className='Tasks'>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      {tasks.map((task) => (
        <TaskBlock 
          key={task._id} 
          title={task.title} 
          goTo={task.goTo} 
          reward={task.reward} 
          completed={completedTasks.includes(task._id)}
          onCheck={() => handleCheckTask(task._id)} 
        />
      ))}
    </div>
  );
}

export default Tasks;
