import React from 'react'
import HomePreview from '../components/Home/HomePreview'
import AddProjectForm from '../components/AddWork/AddProjectForm'

function AddProject() {
  return (
    <div className='AddWork'>
        <HomePreview />

        <div className="AddWork__container">
            <div className="title">
                Добавить проект на биржу
            </div>

            <AddProjectForm />
        </div>
    </div>
  )
}

export default AddProject