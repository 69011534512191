import React from 'react'

function ProfileHeader(props) {
  return (
    <div className='ProfileHeader'>
        <div className="avatar">
            <img src={props.avatar} alt="" />
        </div>

        <div className="info">
            <div className="name">
                {props.name}
            </div>

            <div className="specialnost">
                {props.specialnost}
            </div>
        </div>
    </div>
  )
}

export default ProfileHeader