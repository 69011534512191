import React from 'react'

function MenuItem(props) {
  return (
    <a href={props.to} className='MenuItem'>
        <div className="icon" style={{color: props.iconColor}}>
            {props.icon}
        </div>

        <div className="text">
            {props.text}
        </div>
    </a>
  )
}

export default MenuItem