import React from 'react'

function Portfolio(props) {
  return (
    <div className='Portfolio'>
        <div className="title">
            Портфолио
        </div>

        <div className="grid">
            {props.portfolio.map((item, index) => (
                <img key={index} src={item.image} alt="" />
            ))}
        </div>
    </div>
  )
}

export default Portfolio