import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import MobileNav from './components/MobileNav';
import Search from './pages/Search';
import Chat from './pages/Chat';
import Profile from './pages/Profile';
import AddWork from './pages/AddWork';
import Menu from './pages/Menu';
import Settings from './pages/Settings';
import AddProject from './pages/AddProject';
import Balance from './pages/Balance';
import Referal from './pages/Referal';
import DailyReward from './pages/DailyReward';

const ErrorPage = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>Access Denied</h1>
    <p>This application can only be accessed from within Telegram.</p>
  </div>
);

const RequireTelegramContext = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.Telegram || !window.Telegram.WebApp.initData) {
      navigate('/error');
    }
  }, [navigate]);

  return children;
};

function App() {
  return (
    <BrowserRouter>
      <RequireTelegramContext>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/search' element={<Search />} />
          <Route path='/addabout' element={<AddWork />} />
          <Route path='/addproject' element={<AddProject />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/balance' element={<Balance />} />
          <Route path='/referal' element={<Referal />} />
          <Route path='/dailyreward' element={<DailyReward />} />
          <Route path='/error' element={<ErrorPage />} />
        </Routes>
        <MobileNav />
      </RequireTelegramContext>
    </BrowserRouter>
  );
}

export default App;
