import React, { useState, useEffect } from 'react';
import HomePreview from '../components/Home/HomePreview';
import Tasks from '../components/Tasks/Tasks';
import axios from 'axios';
import WebApp from '@twa-dev/sdk';
import './Home.css';

function Home() {
  const [reward, setReward] = useState(null);
  const [balance, setBalance] = useState(0);
  const [rewardClaimed, setRewardClaimed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchReward = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/reward/${telegramId}`);
        setReward(response.data.rewardAmount);
        setBalance(response.data.balanceMayak);
        setRewardClaimed(true);
        setShowModal(true); // Показать модальное окно при успешном получении награды
      } catch (error) {
        if (error.response && error.response.data.error === 'Reward already claimed for today') {
          setRewardClaimed(true);
        } else {
          console.error('Error fetching reward:', error);
        }
      }
    };

    if (telegramId) {
      fetchReward();
    }
  }, [telegramId]);

  return (
    <div className="Home">
      <HomePreview title="LSpace" text="Текущие задания" />
      <div className="Home__container">
        <Tasks />
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <div className="title">
              Ежедневная награда
            </div>

            <div className="text">
              Вам начислено {reward} $LS коинов!
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
