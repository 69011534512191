import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomePreview from '../components/Home/HomePreview';
import WebApp from '@twa-dev/sdk';
import Notification from '../components/Tasks/Notification';
import { BsCopy } from "react-icons/bs";

function Referal() {
  const [referralLink, setReferralLink] = useState('');
  const [referredUsers, setReferredUsers] = useState([]);
  const [notification, setNotification] = useState(null);
  const telegramId = WebApp.initDataUnsafe.user?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.mayaksecure.com:5000/users/${telegramId}`);
        setReferralLink(response.data.referralLink);
        setReferredUsers(response.data.referredUsers);
      } catch (error) {
        setNotification({ message: 'Error fetching referral data', type: 'error' });
      }
    };

    if (telegramId) {
      fetchData();
    }
  }, [telegramId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        setNotification({ message: 'Реферальная ссылка скопирована в буфер обмена!', type: 'success' });
      })
      .catch((err) => {
        setNotification({ message: 'Ошибка при копировании ссылки', type: 'error' });
      });
  };

  return (
    <div className='Referal'>
      <HomePreview />
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
      <div className="Referal__container">
        <div className="title">
            Ваша реферальная ссылка:
        </div>

        <div className="referalLink">
            <div className="text">
                {referralLink}
            </div>

            <div onClick={handleCopyLink} className="icon">
                <BsCopy />
            </div>
        </div>

        <div className="title">
            Приглашенные люди:
        </div>

        {referredUsers.map((user, index) => (
              <div className='referalUsers' key={index}>
                <div className="left">
                    {index + 1}. {user.nickname || user.telegramId}
                </div>

                <div className="right">
                    +150 points
                </div>
              </div>
        ))}
      </div>
    </div>
  );
}

export default Referal;
