import React from 'react'
import { SiTon } from "react-icons/si";

function ProjectBlock(props) {
  return (
    <div className='ProjectBlock'>
        <div className="ProjectBlockHeader">
            <div className="avatar">
                <img src={props.author.avatar} alt="" />
            </div>

            <div className="name">
                {props.author.name}
            </div>
        </div>

        <div className="ProjectBlockBody">
            <div className="date">
                Добавлено {props.date}
            </div>

            <div className="title">
                {props.title}
            </div>

            <div className="description">
                {props.description}
            </div>

            <div className="projectInfo">
                <div className="responses">
                    Откликов: {props.responses.length}
                </div>

                <div className="price">
                    <div className="number">
                        {props.price}
                    </div>

                    <div className="icon">
                        <SiTon />
                    </div>
                </div>
            </div>

            <a className='responseButton' href='#'>
                Откликнуться
            </a>
        </div>
    </div>
  )
}

export default ProjectBlock